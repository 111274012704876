<template>
<div>
        <v-btn
        @click="exportToExcel('xlsx')"
        type="button"
        color="primary"
        class="mb-2"
        small
        >Excel</v-btn
      >
      <v-btn
        @click="exportToExcel('csv')"
        type="button"
        color="primary"
        class="mb-2 ml-2"
        small
        >CSV</v-btn
      >
      <v-btn
        @click="copyData()"
        type="button"
        color="primary"
        class="mb-2 ml-2"
        small
        >Copy</v-btn
      >
      </div>
</template>

<script>
import * as XLSX from 'xlsx'

export default{
    name: "ExportButtons",
    props: ["headers", "data", "title"],

    methods: {
        // Export To Excel
    exportToExcel(type) {
      var elt = this.data;
      var wb = XLSX.utils.book_new();
      var ws = XLSX.utils.json_to_sheet(elt);
      XLSX.utils.book_append_sheet(wb, ws, this.title);
      XLSX.writeFile(wb, this.title + "." + type);
    },
    // Copy Function
    copyData() {
      this.loading = true;
      // Set up Text
      let textString = "";
      // Populate Headers
      this.headers.forEach((header) => {
        textString += header.text + " ";
      });
      // Add New Line
      textString += `\n`;
      // Populate each line from array
      this.data.forEach((multi) => {
        for (const key in multi) {
          if (multi.hasOwnProperty(key)) {
            const element = multi[key];
            textString += element + " ";
          }
        }
        // Add New Line
        textString += `\n`;
      });
      // Check if navigator available
      if (!navigator.clipboard) {
        this.fallbackCopyTextToClipboard(textString);
        return;
      }
      // Add text to clipboard
      navigator.clipboard.writeText(textString);
      this.loading = false;
      this.snackbar = true;
      this.text = `Successfully copied ${this.data.length} lines to the clipboard.`;
    },
    fallbackCopyTextToClipboard(text) {
      var textArea = document.createElement("textarea");
      textArea.value = text;

      // Avoid scrolling to bottom
      textArea.style.top = "0";
      textArea.style.left = "0";
      textArea.style.position = "fixed";

      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        var successful = document.execCommand("copy");
        var msg = successful ? "successful" : "unsuccessful";
        this.response = msg;
      } catch (err) {
        this.response == err;
      }
      this.loading = false;
      document.body.removeChild(textArea);
    },
    }


}

</script>
