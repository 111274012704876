<template>
  <v-col :cols="colWidth ? colWidth : 6">
    <v-select
      v-model="selectedCompany"
      v-on:change="setCompany($event)"
      :items="companies"
      item-text="name"
      item-value="code"
      :label="'Select Company' + (optional ? ' (optional)' : '')"
      prepend-inner-icon="business"
      outlined
      dense
    >
    </v-select>
  </v-col>
</template>

<script>
export default {
  model: {
    prop: 'company',
    event: 'select'
  },
  props: {
    company: Object,
    colWidth: Number,
    optional: Boolean 
  },
  data() {
    return {
      selectedCompany: null,
      companies: [],
    }
  },
  methods: {
    setCompany(code) {
      let companySelected = this.companies.find(company => company.code === code);
      this.$emit('chosen', companySelected)
    },
    async loadInitialData() {
      let companyArray = await this.$root.sharedData.getCompanies();
      if (companyArray) {
        this.companies = companyArray;
      }      
    }
  },
  created() {
    this.loadInitialData();    
    if (this.company && Object.keys(this.company).length > 0) {
      if (this.company.code) {
        this.selectedCompany = this.company.code;
      }
    }
  }
}
</script>

<style>

</style>