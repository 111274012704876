import * as XLSX from 'xlsx';
import officeCrypto from 'officecrypto-tool';

const Excel = {
  methods: {
    extractJson(file) {
      var fileReader = new FileReader();
      return new Promise((resolve, reject) => {
        try {
          fileReader.onload = function (e) {
            // pre-process data
            var binary = '';
            var bytes = new Uint8Array(e.target.result);
            var length = bytes.byteLength;
            for (var i = 0; i < length; i++) {
              binary += String.fromCharCode(bytes[i]);
            }
            // call 'xlsx' to read the file
            var oFile = XLSX.read(binary, {
              type: 'binary',
              cellDates: true,
              cellStyles: true,
            });
            var sheetName = oFile.SheetNames[0];
            var spreadhsheet = XLSX.utils.sheet_to_json(
              oFile.Sheets[sheetName]
            );
            resolve(spreadhsheet);
          };
        } catch (error) {
          reject(error);
        }

        fileReader.readAsArrayBuffer(file);
      });
    },
    async readFileAsBinary(file) {
      var fileReader = new FileReader();
      return new Promise((resolve) => {
        fileReader.onload = async function (e) {
          const binaryStr = e.target.result;
          resolve(binaryStr);
        };
        fileReader.readAsBinaryString(file);
      });
    },
    /*
     * rowObj expects an object with format: { header: "cell example", headerTwo: "cell two example"}
     */
    generateTemplate(rowObj, filename) {
      let tempArray = [];
      tempArray.push(rowObj);
      var elt = tempArray;
      var wb = XLSX.utils.book_new();
      var ws = XLSX.utils.json_to_sheet(elt);
      XLSX.utils.book_append_sheet(wb, ws, filename.substring(0, 29));
      XLSX.writeFile(wb, filename + '.xlsx');
    },

    /*
     * sheets expects to be an array as such: [
     *  {name: 'sheet1', data: [ {col1: 'row1_1', col2: 'row1_2'}, {col1: 'row2_1', col2: 'row2_2'} ] },
     *  {name: 'sheet2', data: [{col1: 'row1_1'}, {col1: 'row2_1'}] }
     *  ]
     */
    generateTemplateMultiSheets(sheets, filename){
      let wb = XLSX.utils.book_new();
      let counter = 0
      for(const sheet of sheets){
        counter++
        let ws = XLSX.utils.json_to_sheet(sheet.data);
        let sheetName = sheet.name ? sheet.name.substring(0, 29) : 'sheet' + counter
        XLSX.utils.book_append_sheet(wb, ws, sheetName);
      }
      let trimmedName = filename ? filename.substring(0, 50) + '.xlsx'  : 'template..xlsx'
      XLSX.writeFile(wb,  trimmedName )
    },
    async decryptPassword(binaryStr, password) {
      const buffer = new Buffer.from(binaryStr, 'binary');
      return await officeCrypto.decrypt(buffer, {
        password: password,
      })
    },
    parseFormulasOfSheet(sheet, functionToExtract) {
      for (const cellRef in sheet) {
        if (sheet.hasOwnProperty(cellRef) && sheet[cellRef].f) {
          const formula = sheet[cellRef].f;
          const extractedValue = functionToExtract(formula);
          // set cell value
          sheet[cellRef].v = extractedValue;
          // change type to string
          sheet[cellRef].t = 's';
        }
      }
      return sheet;
    },
  },
};

export default Excel;
